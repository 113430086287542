import React from 'react';
import { compose } from 'redux';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'src/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogWithTitle from '../DialogWithTitle';
import { useTranslation } from '../../src/i18n';
import { withSnackbar } from '../SnackbarNotification';
import { useDialogs } from '../../utils/hooks/useDialogs';
import ImageTag from '../ImageTag';
import { TP } from 'constants/index';
import LogoIcon from '../../public/images/icons/logo.svg';
import BlueTriangleIcon from '../../public/images/icons/blue-triangle.svg';
import WhiteTriangleIcon from '../../public/images/icons/white-triangle.svg';

const useStyles = makeStyles()(theme => ({
  churnedPopup: {
    '& .MuiDialog-paperScrollPaper': {
      display: 'flex',
      borderRadius: '8px',
      minWidth: '347px',
      maxWidth: '480px',
      [theme.breakpoints.down('sm')]: {
        overflowY: 'inherit',
      },
    },
    '& .MuiDialog-paperFullScreen': {
      height: 'unset',
      overflowY: 'inherit',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-scrollPaper': {
        alignItems: 'flex-end',
      },
    },
  },
  wrapper: { display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '16px 60px' },
  title: {
    marginBottom: '15px',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#3A3A3A',
  },
  counter: {
    fontSize: '20px',
    color: '#0067C5',
    marginBottom: '15px',
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
  },
  logoIcon: { marginRight: '40px' },
  sourceIcon: { marginLeft: '28px' },
  icon: { marginRight: '12px' },

  info: {
    marginTop: '40px',
    fontStyle: 'italic',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#7F7F7F',
  },
  closeButton: {
    [theme.breakpoints.down('sm')]: {
      top: '-32px',
      background: 'black',
      color: 'white',
      padding: '4px',
      boxShadow: '0 2px 10px 2px rgb(0 0 0 / 50%)',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
}));

const RedirectionPopup = () => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const { t } = useTranslation('NS_APP_GLOBALS');
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    displayRedirectionPopup,
    setDisplayRedirectionPopup,
    redirectionPopupMedia,
    productionDetails,
  } = useDialogs();
  const initialArrowSources = [WhiteTriangleIcon, BlueTriangleIcon, BlueTriangleIcon];

  const [arrowSources, setArrowSources] = React.useState(initialArrowSources);
  const [intervalHandler, setIntervalHandler] = React.useState();
  const handleClose = () => {
    setDisplayRedirectionPopup(false);
  };

  const rotateRight = arr => {
    let last = arr.pop();
    arr.unshift(last);
    return arr;
  };

  React.useEffect(() => {
    if (displayRedirectionPopup) {
      const handler = setInterval(() => {
        setArrowSources(rotateRight([...arrowSources]));
      }, 1000);
      setIntervalHandler(handler);
    } else {
      clearInterval(intervalHandler);
    }
    return () => clearInterval(intervalHandler);
  }, [displayRedirectionPopup, arrowSources]);

  return (
    <DialogWithTitle
      className={classes.churnedPopup}
      contentClass={classes.contentClass}
      open={displayRedirectionPopup}
      onClose={handleClose}
      fullScreen={fullScreen}
      titleClass={classes.title}
      closeButtonClass={classes.closeButton}
    >
      <div className={classes.wrapper}>
        <div className={classes.title}>Taking you to {redirectionPopupMedia?.url}</div>

        <div className={classes.icons}>
          <ImageTag src={LogoIcon} className={classes.logoIcon} />
          <ImageTag src={arrowSources[0]} className={classes.icon} />
          <ImageTag src={arrowSources[1]} className={classes.icon} />
          <ImageTag src={arrowSources[2]} className={classes.icon} />

          <ImageTag src={productionDetails?.partnerSource} className={classes.sourceIcon} />
        </div>
        <div className={classes.info}>
          {productionDetails?.productionName && (
            <>
              {t(`${TP}.FN_FPV_OF`)} <span className={classes.bold}>{productionDetails?.productionName}</span>
            </>
          )}
        </div>
      </div>
    </DialogWithTitle>
  );
};

export default withSnackbar(RedirectionPopup);
