import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from 'src/makeStyles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { colors } from '../../constants/styleConstants';

const useStyles = makeStyles()(theme => ({
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '9px',
    opacity: '0.86',
    color: colors.black,
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
  },
  dialogContent: {
    margin: 0,
    padding: 0,
    borderTop: 'solid 1px rgb(58, 58, 58, 0.2)',
  },
}));

const DialogWithTitle = ({
  children,
  onClose,
  open,
  titleComponent,
  fullScreen,
  fullWidth,
  maxWidth,
  className,
  contentClass,
  titleClass,
  closeButtonClass,
  closeButton = true,
}) => {
  const { classes, cx } = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={cx(classes.root, className)}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      {titleComponent && (
        <MuiDialogTitle disableTypography className={cx(titleClass, classes.dialogTitle)}>
          {titleComponent}
        </MuiDialogTitle>
      )}
      {onClose && closeButton ? (
        <IconButton
          aria-label="close"
          className={cx(classes.closeButton, closeButtonClass)}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}

      <MuiDialogContent className={cx(classes.dialogContent, contentClass)}>{children}</MuiDialogContent>
    </Dialog>
  );
};

DialogWithTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  titleComponent: PropTypes.node,
  fullScreen: PropTypes.bool,
  maxWidth: PropTypes.string,
  className: PropTypes.string,
  contentClass: PropTypes.string,
  closeButton: PropTypes.bool,
};

export default DialogWithTitle;
