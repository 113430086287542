import React from 'react';
import PropTypes from 'prop-types';
import Img from 'react-image';
import LazyLoad from 'react-lazyload';

const ImageTag = props => {
  const { alt, lazyLoad, src, placeholder, isSSRElgible = false, ...imageProps } = props;
  const imageAlt = alt ?? 'image';

  if (isSSRElgible) {
    return <img {...imageProps} src={src} alt={imageAlt} />;
  }

  const imageSrc = [src];

  if (placeholder) {
    imageSrc.push(placeholder);
  }

  if (lazyLoad === false) {
    return <Img {...imageProps} src={imageSrc} alt={imageAlt} />;
  }

  return (
    <LazyLoad>
      <Img {...imageProps} src={imageSrc} alt={imageAlt} />
    </LazyLoad>
  );
};

ImageTag.propTypes = {
  lazyLoad: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

ImageTag.defaultProps = {
  lazyLoad: false,
  placeholder: '/images/placeholder.png',
};

export default ImageTag;
